import { patchState, signalStore, withMethods, withState } from '@ngrx/signals';

import { UserInformation, UserState } from './user.types';

const userInitialState: UserState = {
	user: {
		name: '',
		email: '',
		givenName: '',
		groups: [],
		sub: '',
	},
	selectedGroup: '',
};

export const UserStore = signalStore(
	{ providedIn: 'root' },
	withState(userInitialState),
	withMethods((store) => ({
		updateUser(userData: Partial<UserInformation>) {
			patchState(store, (state) => ({ ...state, user: { ...state.user, ...userData } }));
		},
		updateSelectedGroup(group: string) {
			patchState(store, (state) => ({ ...state, selectedGroup: group }));
		},
	})),
);
